<template>
  <div>
    <div >
      <section class="text-center mb-5">
        <img :src="bannerUrl"   class="img-fit w-100 lazyload"  >
      </section>
      <section class="mb-4">
        <div class="container">
          <div class="text-center my-4">
            <h1 class="h2 fw-600">秒杀活动标题</h1>
            <div class="aiz-count-down aiz-count-down-lg ml-3 align-items-center justify-content-center" >
              <div class="countdown-item">
                <span class="countdown-digit">50</span>
              </div>
              <span class="countdown-separator">:</span>
              <div class="countdown-item">
                <span class="countdown-digit">08</span>
              </div>
              <span class="countdown-separator">:</span>
              <div class="countdown-item">
                <span class="countdown-digit">09</span>
              </div>
              <span class="countdown-separator">:</span>
              <div class="countdown-item">
                <span class="countdown-digit">54</span>
              </div>
            </div>
          </div>
          <div class="row gutters-5 row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2">
            <div class="col mb-2" v-for="(item, i) in goodsList" :key="i">
              <div class="carousel-box" >
                <div class="aiz-card-box border border-light rounded hov-shadow-md mt-1 mb-2 has-transition bg-white">
                  <span class="badge-custom">离开<span class="box ml-1 mr-0">&nbsp;6%</span></span>
                  <div class="position-relative">
                    <a  class="d-block">
                      <img  class="img-fit lazyload mx-auto h-140px h-md-210px" :src="item.url"  >
                    </a>
                    <span v-if="item.wholesale" class="absolute-bottom-left fs-11 text-white fw-600 px-2 lh-1-8" style="background-color: #455a64">
                      批发
                    </span>
                    <div class="absolute-top-right aiz-p-hov-icon">
                      <a href="javascript:void(0)"  title="添加到愿望清单" >
                        <i class="la la-heart-o"></i>
                      </a>
                      <a href="javascript:void(0)"  title="加入对比" >
                        <i class="las la-sync"></i>
                      </a>
                      <a href="javascript:void(0)"  title="添加到购物车" >
                        <i class="las la-shopping-cart"></i>
                      </a>
                    </div>
                  </div>
                  <div class="p-md-3 p-2 text-left">
                    <div class="fs-15">
                      <del class="fw-600 opacity-50 mr-1">{{resetPrice(item.oldPrice)}}</del>
                      <span class="fw-700 text-primary">{{resetPrice(item.price)}}</span>
                    </div>
                    <div class="rating rating-sm mt-1">
                      <i class="las la-star active" v-for="m in item.star" :key="m"></i>
                      <i class="las la-star" v-for="n in 5" :key="n" v-if="n > item.star"></i>
                    </div>
                    <h3 class="fw-600 fs-13 text-truncate-2 lh-1-4 mb-0 h-35px">
                        <a  class="d-block text-reset">{{item.name}}</a>
                    </h3>
                    <div class="rounded px-2 mt-2  border">
                        俱乐部积分:
                        <span class="fw-700 float-right">{{item.point}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
import { resetPrice } from '@/utils/common'
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      bannerUrl: require('../../assets/imgs/flash.png'),
      goodsList: [
        {
          url: require('../../assets/imgs/goods1.jpg'),
          name: '商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称',
          oldPrice: 119,
          price: 100,
          star: 0,
          point: 30,
          wholesale: true
        },
        {
          url: require('../../assets/imgs/goods2.jpg'),
          name: '商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称',
          oldPrice: 119,
          price: 100,
          star: 3,
          point: 30
        },
        {
          url: require('../../assets/imgs/goods1.jpg'),
          name: '商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称',
          oldPrice: 119,
          price: 100,
          star: 0,
          point: 30
        },
        {
          url: require('../../assets/imgs/goods2.jpg'),
          name: '商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称',
          oldPrice: 119,
          price: 100,
          star: 5,
          point: 30
        },
        {
          url: require('../../assets/imgs/goods1.jpg'),
          name: '商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称',
          oldPrice: 119,
          price: 100,
          star: 0,
          point: 30
        },
        {
          url: require('../../assets/imgs/goods2.jpg'),
          name: '商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称',
          oldPrice: 119,
          price: 100,
          star: 0,
          point: 30
        },
      ],
    }
  },
  methods: {
    resetPrice,
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
  }
}
</script>